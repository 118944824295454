/* google font */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;&family=VT323&display=swap');

/* layout styles */
:root {
  --primary: #d4ba75;
  --secondary: #1d1c1c;
  --tirceary: #7286a0;
  --fourth: #323b48;
  --fifth: #b2c9ab;
  --error: crimson;
  --edit: #59d371;
}
body {
  background: var(--tirceary);
  margin: 0;
  font-family: "PT Sans", sans-serif;
}
header {
  background: var(--secondary);
}
header .container {
  margin: 0 auto;
  padding: 10px 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
}
.container h1 {
  position: relative;
  top: 5px;
  font-size: 60px;
  padding: 0;
  margin: 0;
  color: var(--primary);
  font-weight: normal;
}
header a{
  color: var(--primary);
  text-decoration: none;
  font-size: 16px;
}

.App-Logo {
  width: 100px;
  padding: 0;
  margin: 0;
}

.pages {
  max-width: 1800px;
  padding: 20px;
  margin: 0 auto;
}
.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 100px;
}
.workouts a {
  text-decoration: none;
}
.center-button {
  display: flex;
  margin: 0;
  justify-content: center;
}
.no-workouts {
  font-size: 24px;
  text-align: center;
  color: var(--secondary);
}

.create-button {
  border-radius: 40px;
  padding: 10px 25px;
  background: var(--fourth);
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
}
.create-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.create-button:hover {
  color: black;
}
.create-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}
.workout-preview {
  background: var(--fourth);
  border-radius: 10px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.details-container, .edit-container {
  background: var(--fourth);
  color: var(--primary);
  border-radius: 4px;
  margin: 20px auto;
  padding: 20px;
  position: relative;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
}

.workout-preview h2{
  margin: 0 0 10px 0;
  font-size: 1.2em;
  color: var(--primary);
}
.workout-preview p{
  margin: 0;
  font-size: 0.9em;
  color: var(--primary);
  text-decoration: none;
}

.exercise-name {
  text-align: center;
}

.exercise-table {
  border-collapse: collapse;
  width: 1000px;
  color: #333;
  font-family: Arial, sans-serif;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  margin: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.exercise-table th {
  background-color: var(--secondary);
  color: var(--primary);
  font-weight: bold;
  padding: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-top: 1px solid var(--secondary);
  border-bottom: 1px solid var(--secondary);
}

.exercise-table tr:nth-child(even) td {
  background-color: var(--tirceary);
}

.exercise-table tr:hover td {
  background-color: var(--primary);
}

.exercise-table td {
  background-color: var(--fifth);
  padding: 10px;
  border-bottom: 1px solid var(--secondary);
  font-weight: bold;
}

.home-button {
  border-radius: 40px;
  padding: 15px 40px;
  margin-right: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 20px;
}
.home-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.home-button:hover {
  color: black;
}
.home-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.delete-workout {
  border-radius: 40px;
  padding: 15px 40px;
  margin-left: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid rgb(220, 23, 23);
  z-index: 1;
  color: white;
  font-size: 20px;
}
.delete-workout:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: rgb(220, 23, 23);
  transition: all 0.3s ease;
}
.delete-workout:hover {
  color: black;
}
.delete-workout:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

/** Create Workout Styles **/
.create-workout {
  max-width: 1200px;
  padding: 20px;
  margin: 0 auto;
  background-color: var(--fourth);
  border-radius: 10px;
}
.create-workout h3{
  text-align: center;
}
.label-input-container{
  padding: 10px 0;  
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.title-label{
  font-size: 20px;
  font-weight: bold;
  color: var(--fifth);
  margin-right: 10px;
  margin-top: 7px;
}
.title-input {
  width: 300px;
  height: 25px;
  font-size: 20px;
  border-radius: 5px;
  color: var(--fifth);
  background-color: var(--secondary);
  outline: none;
}
.confirm-title-button-container{
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.confirm-title {
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.confirm-title:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.confirm-title:hover {
  color: black;
}
.confirm-title:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}
.workout-title{
  font-size: 40px;
  color: var(--fifth);
  text-decoration: underline;
}

.eform-input {
  width: 200px;
}
.exercise-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--fifth);
  font-size: 18px;
}
.eform-table {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.eform-table input, .sets-form input {
  margin-bottom: 20px;
  font-size: 20px;
  background-color: var(--secondary);
  color: var(--fifth);
  height: 25px;
  width: 250px;
}
.eform-table select, .sets-form select {
  background-color: var(--secondary);
  color: var(--fifth);
  outline: none;
  height: 30px;
  font-size: 20px;
  margin-bottom: 20px;
  width: 250px;
}
.eform-table input:focus, .sets-form input:focus {
  outline: none !important;
  border-color: var(--fifth);
}

.eform-row {
  width: 75%;
}

.eform-col-1 {
  float: left;
  display: flex;
  flex-direction: column;
}

.eform-col-2 {
  float: right;
  display: flex;
  flex-direction: column;
}

.add-exercise-container {
  display: flex;
  justify-content: center;
}
.add-exercise-button {
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.add-exercise-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.add-exercise-button:hover {
  color: black;
}
.add-exercise-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}
.add-workout-container {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 40px;
}
.add-workout-button {
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.add-workout-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.add-workout-button:hover {
  color: black;
}
.add-workout-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

/* Sets Form styling */
.sets-form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.create-sets {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.sets-col-1 {
  width: 10%;
  color: var(--primary);
  float: top;
  padding-right: 25px;
  font-size: 26px;
  font-weight: normal;
}

.sets-col-2 {
  width: 32.5%;
}

.sets-col-3 {
  width: 32.5%;
}

.sets-col-4 {
  width: 15%;
}

.add-set-button {
  width: 100%;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--edit);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.add-set-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--edit);
  transition: all 0.3s ease;
}
.add-set-button:hover {
  color: black;
}
.add-set-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.edit-set-button {
  width: 100%;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid #bd51bd;
  z-index: 1;
  color: white;
  font-size: 16px;
}
.edit-set-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: #bd51bd;
  transition: all 0.3s ease;
}
.edit-set-button:hover {
  color: black;
}
.edit-set-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.text-from-icon {
  margin-left: 5px;
}

nav {
  display: flex;
  align-items: center;
}

nav a {
  margin-left: 10px;
}

nav button {
  background: #fff;
  color: var(--primary);
  border: 2px solid var(--primary);
  padding: 6px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1em;
}
nav button {
  width: 100%;
  max-width: 150px;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 16px;
}
nav button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
nav button:hover {
  color: black;
}
nav button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}


form.signup, form.login {
  max-width: 600px;
  margin: 40px auto;
  padding: 20px;
  background: var(--fourth);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
}

.signup h3, .login h3 {
  color: var(--fifth);
  font-size: 30px;
  text-decoration: underline;
}

.signup label, .login label {
  color: var(--fifth);
  padding-bottom: 5px;
  font-size: 18px;
}

.login input {
  margin-bottom: 20px;
  font-size: 20px;
  background-color: var(--secondary);
  color: var(--fifth);
  height: 25px;
  width: 350px;
  border-radius: 5px;
}

.signup input {
    margin-bottom: 20px;
    font-size: 20px;
    background-color: var(--secondary);
    color: var(--fifth);
    height: 25px;
    width: 350px;
    border-radius: 5px;
    outline: none;
}

.signup input:focus, .login input:focus {
  outline: none !important;
  border-color: var(--fifth);
}

.login-button {
  width: 100%;
  max-width: 200px;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--fifth);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.login-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--fifth);
  transition: all 0.3s ease;
}
.login-button:hover {
  color: black;
}
.login-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.signup-button {
  width: 100%;
  max-width: 200px;
  border-radius: 40px;
  padding: 10px 25px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--primary);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.signup-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--primary);
  transition: all 0.3s ease;
}
.signup-button:hover {
  color: black;
}
.signup-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

div.error {
  padding: 10px;
  background: #ffefef;
  border: 1px solid var(--error);
  color: var(--error);
  border-radius: 4px;
  margin: 20px 0;
}

.user-login-display {
  color: var(--primary);
}

.left-side-nav {
  display: flex;
} 

.app-name {
  padding-top: 20px;
}

.rsnav-item{
  padding-left: 20px;
  font-size: 26px;
}

.right-side-nav {
  display: flex;
}

.user-login-display {
  width: 300px;
  padding-top: 25px;
  padding-right: 20px;
  font-size: 18px;
}

.edit-workout-button {
  border-radius: 40px;
  padding: 15px 40px;
  margin-left: 10px;
  margin-right: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--edit);
  z-index: 1;
  color: white;
  font-size: 20px;
}
.edit-workout-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--edit);
  transition: all 0.3s ease;
}
.edit-workout-button:hover {
  color: black;
}
.edit-workout-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.label-input-container{
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
}

.home-button-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
}

.label-input-container .title-label {
  padding-bottom: 10px;
}

.label-input-container .title-input {
  padding-top: 5px;
  padding-bottom: 5px;
}

.confirm-title-button {
  max-width: 150px;
  border-radius: 40px;
  padding: 10px 25px;
  margin-left: 50px;
  margin-right: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--edit);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.confirm-title-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--edit);
  transition: all 0.3s ease;
}
.confirm-title-button:hover {
  color: black;
}
.confirm-title-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.new-title-line {
  display: inline;
}

.confirm-edits-button {
  max-width: 100%;
  border-radius: 40px;
  padding: 10px 25px;
  margin-left: 10px;
  margin-right: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--edit);
  z-index: 1;
  color: white;
  font-size: 16px;
  text-align: center;
}
.confirm-edits-button:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--edit);
  transition: all 0.3s ease;
}
.confirm-edits-button:hover {
  color: black;
}
.confirm-edits-button:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.confirm-edits-button-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
}

.done-edits-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.edit-container h2, h1 {
  color: var(--fifth);
}
.done-edits-wrap h2 {
  padding: 12px 25px;
  margin-right: 10px;
}

.submit-edit {
  border-radius: 40px;
  padding: 12px 25px;
  margin-right: 10px;
  background: transparent;
  outline: none !important;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  border: 2px solid var(--edit);
  z-index: 1;
  color: white;
  font-size: 16px;
}
.submit-edit:after {
  position: absolute;
  content: "";
  border-radius: 40px;
  width: 0;
  height: 100%;
  top: 0;
  right: 0;
  direction: ltr;
  z-index: -1;
  background: var(--edit);
  transition: all 0.3s ease;
}
.submit-edit:hover {
  color: black;
}
.submit-edit:hover:after {
  left: 0;
  right: 0;
  width: 100%;
}

.sets-form input {
  width: 150px;
}

.sets-form select {
  width: 150px;
}
